import { Record } from 'immutable'
import moment from 'moment'

const DashboardRecord = Record({
  id: '',
  swipedCounts: null,
  offeredCounts: null,
  unofferedCounts: null,
  matchingCounts: null,
  likeCounts: null,
  createdAt: null,
})

class Dashboard extends DashboardRecord {
  static COLLECTION_NAME = 'dashboards'

  static KEY_NAMES = {
    id: 'ユーザID',
    swipedCounts: 'スワイプ数',
    offeredCounts: 'オファー数',
    unofferedCounts: '興味なし数',
    matchingCounts: 'マッチング数',
    likeCounts: '気になる数',
    createdAt: '作成日',
  }

  get createdAtString() {
    const createdAtMoment = moment((this.createdAt.seconds || 0) * 1000)
    return createdAtMoment.isValid()
      ? createdAtMoment.format('YYYY-MM-DD hh:mm')
      : ' - '
  }
}

export default Dashboard
