import type { ReactNode } from 'react'
import { type FC, memo, useState } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  children: ReactNode
  message: string
  width?: string
  position?: 'top' | 'bottom'
}

const Container = styled.div`
  position: relative;
`

const Title = styled.div`
  cursor: pointer;
`

const Popup = styled.div<{
  isActive: boolean
  width?: Props['width']
  position?: Props['position']
}>`
  position: absolute;
  z-index: 1;
  width: ${(props) => props.width};
  padding: 10px;
  font-size: 12px;
  line-height: 1.6em;
  color: #6d6d71;
  text-align: left;
  white-space: pre-line;
  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  background-color: #fff;
  isolation: isolate;
  border: 1px solid #6d6d71;
  border-radius: 5px;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: visibility 0.3s, opacity 0.3s;
  ${(props) => getPopupPositionStyle(props.position)};

  &::before {
    position: absolute;
    width: 15px;
    height: 15px;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border-top: 1px solid #6d6d71;
    border-left: 1px solid #6d6d71;
    ${(props) => getPopupBeforeStyle(props.position)};
  }
`
const getPopupPositionStyle = (position: Props['position'] = 'top') => css`
  ${position === 'top'
    ? css`
        bottom: 35px;
        left: -20px;
      `
    : css`
        top: 35px;
        left: -20px;
      `}
`

const getPopupBeforeStyle = (position: Props['position'] = 'top') => css`
  ${position === 'top'
    ? css`
        bottom: 0;
        left: 21px;
        transform: translateY(50%) rotate(225deg);
      `
    : css`
        top: -16px;
        left: 21px;
        transform: translateY(50%) rotate(45deg);
      `};
`

export const ToolTip: FC<Props> = memo(
  ({ message, width = '280px', position, children }) => {
    const [isActive, setIsActive] = useState(false)

    return (
      <Container>
        <Title
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
        >
          {children}
        </Title>
        <Popup
          width={width}
          isActive={isActive}
          position={position}
        >
          {message}
        </Popup>
      </Container>
    )
  },
)

ToolTip.displayName = 'ToolTip'
