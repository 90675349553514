import Repository from 'repositories/Repository'
import Like from 'models/Like'

class LikeRepository extends Repository {
  constructor() {
    super()

    this.collection = this.mainFirestore.collection('likes')
  }

  findById(likeId) {
    return this.collection
      .doc(likeId)
      .get({ source: 'server' })
      .then((doc) => {
        if (!doc.exists) {
          return null
        }
        const like = new Like({ id: doc.id, ...doc.data() })
        return like
      })
      .catch((error) => {
        throw error
      })
  }

  findByCompanyId(companyId) {
    const result = []
    return this.collection
      .where('aceCompanyId', '==', companyId)
      .orderBy('createdAt', 'desc')
      .get({ source: 'server' })
      .then((docs) => {
        docs.forEach((doc) => {
          const like = new Like({ id: doc.id, ...doc.data() })
          result.push(like)
        })
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}

const likeRepository = new LikeRepository()

Object.freeze(likeRepository)

export default likeRepository
