import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import Repository from 'repositories/Repository'
import AcquisitionOfferCount from 'models/AcquisitionOfferCount'
import Company from 'models/Company'

// TODO: リリース後対応 型の整理
type DashboardData = {
  remainCount?: number
  monthlyOfferCount?: number
  shortOfferCount?: number
  bonusOfferCount?: number
  thisMonthShortData?: AcquisitionOfferCount[]
}

class AcquisitionOfferCountRepository extends Repository {
  constructor() {
    super()
  }

  private getRemainCount(
    acquisitionOfferCounts: AcquisitionOfferCount[],
  ): number {
    const activeDocs = acquisitionOfferCounts.filter((v) => !v.isExpired())

    if (!activeDocs.length) {
      return 0
    }

    const remainCounts = activeDocs.map((doc) => doc.remainCount)
    const totalRemainCount = remainCounts.reduce((a, b) => a! + b!)

    return totalRemainCount as number
  }

  private getMonthlyOfferCount(
    acquisitionOfferCounts: AcquisitionOfferCount[],
  ): number {
    const activeDocs = acquisitionOfferCounts.filter((v) => {
      if (v.isExpired()) return false
      return v.type === 5
    })
    const remainCounts = activeDocs.map((doc) => doc.remainCount)
    const totalRemainCount =
      remainCounts.length && remainCounts.reduce((a, b) => a! + b!)

    return totalRemainCount as number
  }

  private getShortOfferCount(
    acquisitionOfferCounts: AcquisitionOfferCount[],
  ): number {
    const activeDocs = acquisitionOfferCounts.filter((v) => {
      if (v.isExpired()) return false
      return v.type === 1
    })
    const remainCounts = activeDocs.map((doc) => doc.remainCount)
    const totalRemainCount =
      remainCounts.length && remainCounts.reduce((a, b) => a! + b!)

    return totalRemainCount as number
  }

  private getBonusOfferCount(
    acquisitionOfferCounts: AcquisitionOfferCount[],
  ): number {
    const activeDocs = acquisitionOfferCounts.filter((v) => {
      if (v.isExpired()) return false
      return v.type !== 1 && v.type !== 5
    })
    const remainCounts = activeDocs.map((doc) => doc.remainCount)
    const totalRemainCount =
      remainCounts.length && remainCounts.reduce((a, b) => a! + b!)

    return totalRemainCount as number
  }

  private getThisMonthShortData(
    acquisitionOfferCounts: AcquisitionOfferCount[],
  ) {
    dayjs.extend(isBetween)
    const activeDocs = acquisitionOfferCounts.filter((v) => v.type === 1)
    const thisMonthShortData = activeDocs.filter((v) =>
      // expiredAtが今月中か判定
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dayjs(v.createdAt?.toDate()).isBetween(
        dayjs().startOf('month'),
        dayjs().endOf('month'),
        null,
        '[]',
      ),
    )
    const sortedData = thisMonthShortData.sort((a, b) => {
      const createdAtA = a.createdAt
      const createdAtB = b.createdAt
      if (createdAtA == null || createdAtB == null) {
        return 0
      }
      return createdAtA > createdAtB ? 1 : -1
    })
    return sortedData
  }

  async findByCompanyId(
    companyId: Company['id'],
  ): Promise<AcquisitionOfferCount[]> {
    if (!companyId) {
      throw new Error()
    }
    const results: AcquisitionOfferCount[] = []
    const snapshot = await this.mainFirestore
      .collection(Company.COLLECTION_NAME)
      .doc(companyId)
      .collection(AcquisitionOfferCount.COLLECTION_NAME)
      .get({ source: 'server' })
    if (snapshot.empty) {
      return results
    }
    snapshot.docs.forEach((doc) => {
      const short = new AcquisitionOfferCount({
        id: doc.id,
        ...doc.data(),
      })
      results.push(short)
    })
    return results
  }

  async fetchRemainOfferCount(companyId: string): Promise<number> {
    const acquisitionOfferCounts = await this.findByCompanyId(companyId)

    const totalRemainCount = this.getRemainCount(acquisitionOfferCounts)

    return totalRemainCount
  }

  // remainCount更新対象のIDを返却
  async getDecrementTargetId(companyId: string) {
    // acquisitionOfferCountsのドキュメントをすべて取得
    const acquisitionOfferCounts = await this.findByCompanyId(companyId)

    const typesInOrder = [5, 1, 0, 2, 3, 4]
    for (const type of typesInOrder) {
      const filteredAndSortedData = acquisitionOfferCounts
        .filter((v) => v.type === type && v.remainCount !== 0)
        .sort((a, b) => {
          if (a.createdAt === null || b.createdAt === null) {
            return 0
          }
          /* eslint-disable @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          return a.createdAt - b.createdAt
        })

      if (filteredAndSortedData.length) {
        return filteredAndSortedData[0]?.id
      }
    }

    // acquisitionOfferCountsのすべてのドキュメントのremainCountが0の場合
    throw new Error('Reach offer count')
  }

  async fetchDashboardData(companyId: string): Promise<DashboardData> {
    const acquisitionOfferCounts = await this.findByCompanyId(companyId)

    const remainCount = this.getRemainCount(acquisitionOfferCounts)

    const monthlyOfferCount = this.getMonthlyOfferCount(acquisitionOfferCounts)

    const shortOfferCount = this.getShortOfferCount(acquisitionOfferCounts)

    const bonusOfferCount = this.getBonusOfferCount(acquisitionOfferCounts)

    const thisMonthShortData = this.getThisMonthShortData(
      acquisitionOfferCounts,
    )

    return {
      remainCount: remainCount,
      monthlyOfferCount: monthlyOfferCount,
      shortOfferCount: shortOfferCount,
      bonusOfferCount: bonusOfferCount,
      thisMonthShortData: thisMonthShortData,
    }
  }

  async fetchThisMonthShortCount(companyId: string) {
    const acquisitionOfferCounts = await this.findByCompanyId(companyId)
    const thisMonthShortData = this.getThisMonthShortData(
      acquisitionOfferCounts,
    )
    return thisMonthShortData
  }
}

const acquisitionOfferCountRepository = new AcquisitionOfferCountRepository()

Object.freeze(acquisitionOfferCountRepository)

export default acquisitionOfferCountRepository
