import { type FC, memo } from 'react'
import styled from 'styled-components'
import { NextImage } from 'components/common/NextImage'
import { ToolTip } from 'components/common/ToolTip'
import { Message } from 'constants/Message'
import goodIcon from 'images/legacy/good.svg?url'
import iconQues from 'images/legacy/qes-circle.svg?url'

type Props = {
  companyOfferCountData: any
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  padding: 0 20px;
  background-color: #fff;
`

const CountContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: -10px auto 0;
`

const RemainOfferIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: auto 4px auto 0;
`

const IconWrapper = styled.div`
  width: 20px;
`

const CountDescriptionWrapper = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  font-size: 12px;
  font-weight: 400;
  color: #828282;
`

const CountDescription = styled.div`
  display: flex;
  align-items: end;
  margin-left: 2px;
  line-height: 20px;
`

const CountSuffixText = styled.div`
  margin: 0 0 2px 2px;
`

const CountWrapper = styled.div`
  display: flex;
  align-items: end;
  margin-top: 12px;
`

const CalculationEqualIconWrapper = styled.div`
  margin: auto 12px;
`

const CalculationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-size: 28px;
  color: #adadad;
`

const Count = styled.div`
  font-size: 28px;
  font-weight: 700;
`

export const OfferCount: FC<Props> = memo(({ companyOfferCountData }) => {
  return (
    <Container>
      <CountContainer>
        <CountDescriptionWrapper>
          <CountDescription>残りオファー数</CountDescription>
        </CountDescriptionWrapper>
        <CountWrapper>
          <RemainOfferIconWrapper>
            <NextImage
              src={goodIcon}
              alt=""
              width={24}
              height={24}
            />
          </RemainOfferIconWrapper>
          <Count style={{ color: '#4d76dd' }}>
            {companyOfferCountData.remainCount}
          </Count>
          <CountSuffixText>通</CountSuffixText>
        </CountWrapper>
      </CountContainer>

      <CalculationEqualIconWrapper>
        <CalculationIcon>=</CalculationIcon>
      </CalculationEqualIconWrapper>

      <CountContainer>
        <CountDescriptionWrapper>
          <ToolTip message={Message.TOOLTIP_OFFER_COUNT_MONTHLY}>
            <IconWrapper>
              <NextImage
                src={iconQues}
                alt="question"
              />
            </IconWrapper>
          </ToolTip>
          <CountDescription>うち毎月分</CountDescription>
        </CountDescriptionWrapper>
        <CountWrapper>
          <Count>{companyOfferCountData.monthlyOfferCount}</Count>
          <CountSuffixText>通</CountSuffixText>
        </CountWrapper>
      </CountContainer>

      <CalculationIcon>+</CalculationIcon>

      <CountContainer>
        <CountDescriptionWrapper>
          <ToolTip
            message={Message.TOOLTIP_OFFER_COUNT_SHORT}
            width="260px"
          >
            <IconWrapper>
              <NextImage
                src={iconQues}
                alt="question"
              />
            </IconWrapper>
          </ToolTip>
          <CountDescription>うちショート分</CountDescription>
        </CountDescriptionWrapper>
        <CountWrapper>
          <Count>{companyOfferCountData.shortOfferCount}</Count>
          <CountSuffixText>通</CountSuffixText>
        </CountWrapper>
      </CountContainer>

      <CalculationIcon>+</CalculationIcon>

      <CountContainer>
        <CountDescriptionWrapper>
          <ToolTip message={Message.TOOLTIP_OFFER_COUNT_BONUS}>
            <IconWrapper>
              <NextImage
                src={iconQues}
                alt="question"
              />
            </IconWrapper>
          </ToolTip>
          <CountDescription>うちボーナス</CountDescription>
        </CountDescriptionWrapper>
        <CountWrapper>
          <Count>{companyOfferCountData.bonusOfferCount}</Count>
          <CountSuffixText>通</CountSuffixText>
        </CountWrapper>
      </CountContainer>
    </Container>
  )
})

OfferCount.displayName = 'OfferCount'
