/**
 * @deprecated use below CSS instead
 */
const flex = (justify = 'center', align = 'center') =>
  `
    display: flex;
    justify-content: ${justify};
    align-items: ${align};
  `

export const mixins = {
  flex,
}
