import dayjs from 'dayjs'
import Model from 'models/model'
import type firebase from 'firebase/app'

export default class AcquisitionOfferCount extends Model {
  id?: string
  count?: number
  remainCount?: number
  type?: number
  createdAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
  updatedAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
  expiredAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
    | null
  static COLLECTION_NAME = 'acquisitionOfferCounts'

  constructor(init?: Partial<AcquisitionOfferCount>) {
    super()
    const defaultValues = {
      count: 0,
      remainCount: 0,
      type: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      expiredAt: null,
    }
    Object.assign(this, defaultValues)
    Object.assign(this, init)
  }

  public isExpired() {
    if (this.expiredAt === undefined) {
      throw new Error('Invalid Offer Count')
    }
    if (this.expiredAt === null) return false // 使用期限が無期限
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dayjs().subtract(1, 'd').isAfter(this.expiredAt.toDate()) // 使用期限が昨日以前
  }
}
