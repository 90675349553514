import { isEmpty } from 'lodash'
import Repository from 'repositories/Repository'
import FirebaseManager from 'utils/FirebaseManager'
import Company from 'models/Company'
import Dashboard from 'models/Dashboard'

class DashboardRepository extends Repository {
  findById(dashboardId) {
    return FirebaseManager.getCompanyId()
      .then((companyId) => {
        return this.mainFirestore
          .collection(Company.COLLECTION_NAME)
          .doc(companyId)
          .collection(Dashboard.COLLECTION_NAME)
          .doc(dashboardId)
          .get({ source: 'server' })
      })
      .then((doc) => {
        if (!doc.exists) {
          return null
        }
        const dashboard = new Dashboard({ id: doc.id, ...doc.data() })
        return dashboard
      })
      .catch((error) => {
        throw error
      })
  }

  /// dashboards コレクションからデータを全て取得
  /// from, to はどちらも値がある場合のみ絞り込みがおこなわれ, それ以外は取得したデータをすべて返す
  findAll(from, to) {
    const result = []
    return FirebaseManager.getCompanyId()
      .then((companyId) => {
        // TODO: データが多くなってきたらクエリを from, to 指定に切り替える
        return this.mainFirestore
          .collection(Company.COLLECTION_NAME)
          .doc(companyId)
          .collection(Dashboard.COLLECTION_NAME)
          .get({ source: 'server' })
      })
      .then((docs) => {
        docs.forEach((doc) => {
          // dashboardId は 'YYYYMMDD' 形式になっているので, from ~ to の文字列比較で日付範囲絞り込み可能
          const dashboardId = doc.id

          if (!isEmpty(from) && !isEmpty(to)) {
            if (from <= dashboardId && dashboardId <= to) {
              const dashboard = new Dashboard({
                id: dashboardId,
                ...doc.data(),
              })
              result.push(dashboard)
            }
            return
          }

          const dashboard = new Dashboard({
            id: dashboardId,
            ...doc.data(),
          })
          result.push(dashboard)
        })
        return result
      })
      .catch((error) => {
        throw error
      })
  }
}

const dashboardRepository = new DashboardRepository()

Object.freeze(dashboardRepository)

export default dashboardRepository
