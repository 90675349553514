import { isEmpty } from 'lodash'
import moment from 'moment'
import Model from 'models/model'
import { type InterestActionType } from 'types'
import type firebase from 'firebase'

export default class Like extends Model {
  id?: string
  aceId?: string
  aceCompanyId?: string
  jokerId?: string
  recruitmentId?: string
  isOffered?: boolean
  isAlreadyMatching?: boolean
  selectedInterestAction?: InterestActionType
  createdAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
  updatedAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date

  static COLLECTION_NAME = 'likes'

  constructor(init?: Partial<Like>) {
    super()
    const defaultValues = {
      aceId: '',
      aceCompanyId: '',
      jokerId: '',
      createdAt: new Date(),
      updatedAt: new Date(),
    }
    Object.assign(this, defaultValues)
    Object.assign(this, init)
  }

  get isNotOperated() {
    if (this.isAlreadyMatching) return false
    return this.isOffered === null || this.isOffered === undefined
  }

  get createdAtString() {
    if (isEmpty(this.createdAt)) return ' - '
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const createdAtMoment = moment((this.createdAt.seconds || 0) * 1000)
    return createdAtMoment.isValid()
      ? createdAtMoment.format('YYYY.MM.DD')
      : ' - '
  }

  get updatedAtString() {
    if (isEmpty(this.updatedAt)) return ' - '
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updatedAtMoment = moment((this.updatedAt.seconds || 0) * 1000)
    return updatedAtMoment.isValid()
      ? updatedAtMoment.format('YYYY.MM.DD HH:mm')
      : ' - '
  }
}
