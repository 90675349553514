import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'
import { NextImage } from 'components/common/NextImage'
import GetOfferIconActive from 'images/legacy/getOfferIconActive.svg?url'
import GetOfferIconPassive from 'images/legacy/getOfferIconPassive.svg?url'

const MissionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 89px;
`

const GetOfferWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 6px;
`

const GetOfferCount = styled.span`
  margin-left: 4px;
  font-family: Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 20px;
  color: #4d76dd;
`

const GetOfferDay = styled.span`
  color: #bdbdbd;
`

type Props = {
  isActive: boolean
  createdAt?: string
  isEmphasis?: boolean
}

export const MissionIcon: FC<Props> = memo(
  ({ isActive, createdAt, isEmphasis }) => (
    <MissionContainer>
      <NextImage
        src={isActive ? GetOfferIconActive : GetOfferIconPassive}
        alt=""
        width={62}
        height={62}
        style={{ objectFit: 'contain' }}
      />
      <div>
        <GetOfferWrapper>
          <GetOfferCount>10</GetOfferCount>
          <span>通獲得{isEmphasis && '！'}</span>
        </GetOfferWrapper>
        {isActive && createdAt && <GetOfferDay>{createdAt}</GetOfferDay>}
      </div>
    </MissionContainer>
  ),
)

MissionIcon.displayName = 'MissionIconMissionIcon'
