import type { FC, ReactNode } from 'react'
import { css } from 'libs/panda-css/styled-system/css'

type Props = {
  children: ReactNode
  label?: string
  margin?: string
  padding?: string
  backgroundColor?: string
  overflow?: string
  isOnlyTable?: boolean
  childDisplay?: string
}

const PageContainer: FC<Props> = ({
  children,
  label,
  margin,
  padding,
  backgroundColor,
  overflow,
  isOnlyTable = false,
  childDisplay,
}) => (
  <div
    style={{
      // @ts-expect-error style に CSS Variables に入れるため
      '--padding': padding ?? '20px',
      '--margin': margin ?? '20px 40px',
    }}
    className={css({
      '--_padding': 'var(--padding)',
      '--_margin': 'var(--margin)',
      display: 'flex',
      flexDirection: 'column',
      padding: 'var(--_padding)',
      margin: 'var(--_margin)',
      maxInlineSize: '100dvi',
    })}
  >
    {label && (
      <div
        className={css({
          marginBlockEnd: '10px',
        })}
      >
        {label}
      </div>
    )}
    <div
      style={{
        // @ts-expect-error style に CSS Variables に入れるため
        '--display': childDisplay || 'block',
        '--padding': isOnlyTable ? '0' : '10px',
        '--overflow': overflow || 'visible',
        '--background-color': backgroundColor || 'white',
        '--border-radius': isOnlyTable ? '0' : '10px',
      }}
      className={css({
        '--_display': 'var(--display)',
        '--_padding': 'var(--padding)',
        '--_overflow': 'var(--overflow)',
        '--_background-color': 'var(--background-color)',
        '--_border-radius': 'var(--border-radius)',
        display: 'var(--_display)',
        padding: 'var(--_padding)',
        overflow: 'var(--_overflow)',
        textAlign: 'center',
        backgroundColor: 'var(--_background-color)',
        borderRadius: 'var(--_border-radius)',
      })}
    >
      {children}
    </div>
  </div>
)

export default PageContainer
