import { memo, useEffect, useReducer } from 'react'
import Link from 'next/link'
import { useRouter, withRouter } from 'next/router'
import { useSetRecoilState } from 'recoil'
import { isEmpty } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { listTypeState } from 'globalStates/atoms/offerAtom'
import { IconTextLink } from 'components/common/IconTextLink'
import { MissionIcon } from 'components/common/MissionIcon'
import PageContainer from 'components/common/PageContainer'
import { OfferCount } from 'components/dashboard/OfferCount'
import Loader from 'components/Loader'
import AceRepository from 'repositories/AceRepository'
import acquisitionOfferCountRepository from 'repositories/AcquisitionOfferCountRepository'
import CompanyRepository from 'repositories/CompanyRepository'
import DashboardRepository from 'repositories/DashboardRepository'
import JokerRepository from 'repositories/JokerRepository'
import LikeRepository from 'repositories/LikeRepository'
import MatchingRepository from 'repositories/MatchingRepository'
import { showFailureToast } from 'utils/AppToast'
import FirebaseManager from 'utils/FirebaseManager'
import { LINK_URLS } from 'constants/LinkURL'
import { Message, OfflineErrorMessage } from 'constants/Message'
import { OfferListType } from 'constants/Number'
import { SHORTS } from 'constants/Route'
import QesIcon from 'images/legacy/HelpQuestion.svg?url'
import { mixins } from 'styles/mixins'
import { OPACITY_HOVER, TRANSITION_DURATION } from 'styles/variables'

const TopPageContainer = styled.div`
  min-width: 1200px;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: 14rem 16rem auto;
  grid-template-columns: 50% 50%;
`

const UpperRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;

  &.informal-decision {
    position: relative;
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    div {
      text-align: start;
    }

    button {
      height: 6rem;
      max-height: 6rem;
    }

    a {
      position: absolute;
      bottom: 4.6rem;
      margin-left: 1rem;
      font-size: 1.2rem;
    }
  }

  &.likes {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  &.matchings {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
`

const ScrollListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
`

const ScrollListTable = styled.table`
  width: 100%;
  padding: 0.5rem;
  overflow: hidden;
  table-layout: fixed;

  tbody > tr > td {
    width: 100%;
    overflow: hidden;
    line-height: 1.8rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      padding-bottom: 2px;
      color: #4a7dc0;
      border-bottom: 1px solid #4a7dc0;
    }
  }
`

const OfferLimitContainer = styled.div`
  ${mixins.flex('space-around')};

  height: 100%;
  background-color: #fff;
`

const CategoryHeader = styled.div`
  display: flex;
`

const CategoryLabel = styled.span`
  margin: 0.5rem auto 0.5rem 0.5rem;
  font-weight: 300;
`

const LinkButton = styled.a`
  padding: 6px 27px;
  margin-bottom: 8px;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(0deg, #49c0ad, #4fafc7);
  border: none;
  border-radius: 5px;
  transition: ${TRANSITION_DURATION};

  &:hover {
    opacity: ${OPACITY_HOVER};
  }
`

const SectionWrapper = styled.div`
  ${mixins.flex('space-around')};

  padding: 20px 0;
`

const GetOfferCountMessage = styled.p`
  padding-left: 20px;
  text-align: left;
`

const Divider = styled.div`
  height: 45px;
  margin: auto 0;
  border-right: 1px solid #f2f2f2;
`

export const SelectionStatusContainer = styled.div`
  ${mixins.flex()}

  right: 6rem;
`

export const Select = styled.select`
  width: 10rem;
  height: 2rem;

  :focus {
    border: 1px solid #558ce5;
    outline: 0;
    box-shadow: 0 0 4px 0 rgb(173 173 173 / 50%);
  }
`

const MissionIconsContainer = styled.div`
  ${mixins.flex()}

  margin-left: 8px;
`

const MissionIconWrapper = styled.div`
  padding: 0 20px;
`

const ACTION_TYPES = {
  FINISH_FETCH_DATA: 'FINISH_FETCH_DATA',
  START_FETCH_DATA: 'START_FETCH_DATA',
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_FETCH_DATA:
      return {
        ...state,
        isLoading: action.isLoading,
        isLoadingActivity: action.isLoadingActivity,
        duration: action.duration || state.duration,
      }
    case ACTION_TYPES.FINISH_FETCH_DATA:
      return {
        ...state,
        isLoading: false,
        isLoadingActivity: false,
        company: action.company || state.company,
        aces: action.aces || state.aces,
        jokers: action.jokers || state.jokers,
        companyDashboardData: action.companyDashboardData,
        companyOfferCountData: action.companyOfferCountData,
        dashboardDataPerAce: action.dashboardDataPerAce,
        error: action.error,
        notOperatedLikes: action.notOperatedLikes,
        notOperatedMatchings: action.notOperatedMatchings,
      }
    default:
      return state
  }
}

const Top = memo(() => {
  const router = useRouter()
  const setOfferListType = useSetRecoilState(listTypeState)
  // properties
  const initialState = {
    isLoading: true,
    aces: [],
    jokers: [],
    companyOfferCountData: null,
    notOperatedLikes: [],
    notOperatedMatchings: [],
    error: null,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    isLoading,
    aces,
    companyOfferCountData,
    notOperatedLikes,
    notOperatedMatchings,
    error,
  } = state

  // lifecycles

  useEffect((_) => {
    fetchData(true)
  }, [])

  // functions

  const fetchData = (isFirstRequest, duration) => {
    const selectedDuration = duration || state.duration
    dispatch({
      type: ACTION_TYPES.START_FETCH_DATA,
      duration: selectedDuration,
      isLoading: isFirstRequest,
      isLoadingActivity: !isFirstRequest,
    })

    let allFetchData = {}
    let from = null
    let to = null
    switch (selectedDuration) {
      case 'thisWeek':
        from = moment().startOf('isoWeek').format('YYYYMMDD')
        to = moment().endOf('isoWeek').format('YYYYMMDD')
        break
      case 'lastWeek':
        from = moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .format('YYYYMMDD')
        to = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYYMMDD')
        break
      case 'thisMonth':
        from = moment().startOf('month').format('YYYYMMDD')
        to = moment().endOf('month').format('YYYYMMDD')
        break
      case 'lastMonth':
        from = moment().subtract(1, 'month').startOf('month').format('YYYYMMDD')
        to = moment().subtract(1, 'month').endOf('month').format('YYYYMMDD')
        break
      default:
        break
    }
    FirebaseManager.getCompanyId()
      .then((companyId) => {
        const tasks = [DashboardRepository.findAll(from, to)]
        if (isFirstRequest) {
          tasks.push(CompanyRepository.find())
          tasks.push(AceRepository.findByCompanyId(companyId))
          tasks.push(
            acquisitionOfferCountRepository.fetchDashboardData(companyId),
          )
        }
        return Promise.all(tasks)
      })
      .then((results) => {
        const dashboards = results[0]
        let company = null
        let aces = null
        let companyOfferCountData = null
        if (isFirstRequest) {
          company = results[1]
          aces = results[2]
          companyOfferCountData = results[3]
        } else {
          company = state.company || {}
          aces = state.aces || []
          companyOfferCountData = state.companyOfferCountData
        }

        const dashboardDataPerAce = {}
        const aceIds = []

        let companySwipedCount = 0
        let companyOfferedCount = 0
        let companyMatchingCount = 0
        let companyLikeCount = 0
        let companyOfferRate = 0.0
        let companyMatchingRate = 0.0
        aces.forEach((ace) => {
          const aceId = ace.id
          aceIds.push(aceId)
          let aceSwipedCount = 0
          let aceOfferedCount = 0
          let aceMatchingCount = 0
          let aceLikeCount = 0
          let aceOfferRate = 0.0
          let aceMatchingRate = 0.0
          dashboards.forEach((dashboard) => {
            if (!isEmpty(dashboard.swipedCounts)) {
              aceSwipedCount += dashboard.swipedCounts[aceId] || 0
            }
            if (!isEmpty(dashboard.offeredCounts)) {
              aceOfferedCount += dashboard.offeredCounts[aceId] || 0
            }
            if (!isEmpty(dashboard.matchingCounts)) {
              aceMatchingCount += dashboard.matchingCounts[aceId] || 0
            }
            if (!isEmpty(dashboard.likeCounts)) {
              aceLikeCount += dashboard.likeCounts[aceId] || 0
            }
          })
          if (aceSwipedCount !== 0 && aceOfferedCount !== 0) {
            aceOfferRate =
              Math.round((aceOfferedCount / aceSwipedCount) * 1000) / 1000
          }
          if (aceOfferedCount !== 0 && aceMatchingCount !== 0) {
            aceMatchingRate =
              Math.round((aceMatchingCount / aceOfferedCount) * 1000) / 1000
          }

          dashboardDataPerAce[aceId] = {
            swipedCount: aceSwipedCount,
            offeredCount: aceOfferedCount,
            offerRate: aceOfferRate,
            matchingCount: aceMatchingCount,
            matchingRate: aceMatchingRate,
            likeCount: aceLikeCount,
          }

          companySwipedCount += aceSwipedCount
          companyOfferedCount += aceOfferedCount
          companyMatchingCount += aceMatchingCount
          companyLikeCount += aceLikeCount
        })

        if (companySwipedCount !== 0 && companyOfferedCount !== 0) {
          companyOfferRate =
            Math.round((companyOfferedCount / companySwipedCount) * 1000) / 1000
        }
        if (companyOfferedCount !== 0 && companyMatchingCount !== 0) {
          companyMatchingRate =
            Math.round((companyMatchingCount / companyOfferedCount) * 1000) /
            1000
        }

        const companyDashboardData = {
          swipedCount: companySwipedCount,
          offeredCount: companyOfferedCount,
          offerRate: companyOfferRate,
          matchingCount: companyMatchingCount,
          matchingRate: companyMatchingRate,
          likeCount: companyLikeCount,
        }

        allFetchData = Object.assign(
          {
            company: company,
            aces: aces,
            dashboardDataPerAce: dashboardDataPerAce,
            companyDashboardData: companyDashboardData,
            companyOfferCountData: companyOfferCountData,
          },
          allFetchData,
        )

        return Promise.all([
          LikeRepository.findByCompanyId(company.id),
          MatchingRepository.findByCompanyId(company.id),
        ])
      })
      .then((results) => {
        const likes = results[0] || []
        const matchings = results[1] || []

        const jokerIds = []
        const notOperatedLikes = []
        const notOperatedMatchings = []

        likes.forEach((like) => {
          if (
            like.isNotOperated &&
            !matchings.some((matching) => matching.jokerId === like.jokerId)
          ) {
            notOperatedLikes.push(like)
            if (like.jokerId) {
              jokerIds.push(like.jokerId)
            }
          }
        })
        matchings.forEach((matching) => {
          if (matching.isNotOperated) {
            notOperatedMatchings.push(matching)
            if (matching.jokerId) {
              jokerIds.push(matching.jokerId)
            }
          }
        })

        allFetchData = Object.assign(
          {
            notOperatedLikes: notOperatedLikes,
            notOperatedMatchings: notOperatedMatchings,
          },
          allFetchData,
        )

        const notDuplicatedJokerIds = Array.from(new Set(jokerIds))
        return JokerRepository.select(notDuplicatedJokerIds)
      })
      .then((jokers) => {
        const filteredLikes = []
        allFetchData.notOperatedLikes.forEach((like) => {
          const joker = jokers.find((joker) => joker.id === like.jokerId)
          if (!joker || !joker.isSwipeTarget) return
          filteredLikes.push(like)
        })
        allFetchData.notOperatedLikes = filteredLikes
        allFetchData = Object.assign({ jokers: jokers }, allFetchData)
        // return

        dispatch({
          type: ACTION_TYPES.FINISH_FETCH_DATA,
          ...allFetchData,
        })
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: ACTION_TYPES.FINISH_FETCH_DATA,
          error: error,
        })
      })
  }

  /// rendering
  if (error) {
    const errorMessage =
      error.code === 'unavailable' ? OfflineErrorMessage : null
    showFailureToast(error, errorMessage)
    return null
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <TopPageContainer>
          <PageContainer
            margin="0 0 40px"
            padding="0"
            backgroundColor="clear"
            overflow="inherit"
          >
            <GridContainer>
              <UpperRowContainer>
                <CategoryHeader>
                  <CategoryLabel>オファー数</CategoryLabel>
                </CategoryHeader>
                <OfferCount companyOfferCountData={companyOfferCountData} />
              </UpperRowContainer>
              <UpperRowContainer>
                <CategoryHeader>
                  <CategoryLabel>マンスリーミッション</CategoryLabel>
                  <LinkButton onClick={() => router.push(`${SHORTS}/create`)}>
                    ショートを書く
                  </LinkButton>
                </CategoryHeader>
                <OfferLimitContainer>
                  <SectionWrapper>
                    <div style={{ width: '160px' }}>
                      <GetOfferCountMessage>
                        {Message.LETS_GET_OFFER_COUNT}
                      </GetOfferCountMessage>
                      <IconTextLink
                        label="ショートとは"
                        icon={QesIcon}
                        href={LINK_URLS.shortHelp}
                      />
                    </div>
                    <MissionIconsContainer>
                      <MissionIconWrapper>
                        <MissionIcon
                          isActive={
                            companyOfferCountData.thisMonthShortData.length >= 1
                          }
                          createdAt={
                            companyOfferCountData.thisMonthShortData.length >= 1
                              ? moment(
                                  companyOfferCountData.thisMonthShortData[0].createdAt.toDate(),
                                ).format('YYYY.M.D')
                              : ''
                          }
                          isEmphasis={
                            companyOfferCountData.thisMonthShortData.length >= 1
                          }
                        />
                      </MissionIconWrapper>
                      <Divider />
                      <MissionIconWrapper>
                        <MissionIcon
                          isActive={
                            companyOfferCountData.thisMonthShortData.length >= 2
                          }
                          createdAt={
                            companyOfferCountData.thisMonthShortData.length >= 2
                              ? moment(
                                  companyOfferCountData.thisMonthShortData[1].createdAt.toDate(),
                                ).format('YYYY.M.D')
                              : ''
                          }
                          isEmphasis={
                            companyOfferCountData.thisMonthShortData.length >= 1
                          }
                        />
                      </MissionIconWrapper>
                    </MissionIconsContainer>
                  </SectionWrapper>
                </OfferLimitContainer>
              </UpperRowContainer>
              <UpperRowContainer className="likes">
                <CategoryLabel>未対応気になる一覧</CategoryLabel>
                <ScrollListContainer>
                  <ScrollListTable>
                    <tbody>
                      {notOperatedLikes.map((like, index) => {
                        const ace =
                          aces.find((ace) => ace.id === like.aceId) || {}
                        if (!ace.name) {
                          return null
                        }
                        return (
                          <tr key={index}>
                            <td>
                              {like.createdAtString + '　' || ''}
                              <Link
                                href="/offers"
                                onClick={() =>
                                  setOfferListType(OfferListType.LIKE)
                                }
                              >{`${ace.name}さんへの気になる`}</Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </ScrollListTable>
                </ScrollListContainer>
              </UpperRowContainer>
              <UpperRowContainer className="matchings">
                <CategoryLabel>未読メッセージ一覧</CategoryLabel>
                <ScrollListContainer>
                  <ScrollListTable>
                    <tbody>
                      {notOperatedMatchings.map((matching, index) => {
                        const ace =
                          aces.find((ace) => ace.id === matching.aceId) || {}
                        if (!ace.name) {
                          return null
                        }
                        return (
                          <tr key={index}>
                            <td>
                              {matching.lastMessagedAtString + ' ' || ''}
                              {`[${ace.name}さん] 宛にメッセージが届いています`}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </ScrollListTable>
                </ScrollListContainer>
              </UpperRowContainer>
            </GridContainer>
          </PageContainer>
        </TopPageContainer>
      )}
    </>
  )
})

export default withRouter(Top)

Top.displayName = 'Top'
