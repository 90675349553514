import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'
import { NextImage } from './NextImage'

type Props = {
  label: string
  href: string
  icon: string
}

const ToHelpPageButton = styled.a`
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #1a1a1a;
  cursor: pointer;
  transition: all 100ms ease-in;

  &:hover {
    color: blue;
    text-decoration: underline;
  }

  span {
    padding-left: 6px;
    font-weight: 700;
  }
`

export const IconTextLink: FC<Props> = memo(({ label, href, icon }) => (
  <ToHelpPageButton
    href={href}
    target="_blank"
  >
    <NextImage
      src={icon}
      alt="help"
    />
    <span>{label}</span>
  </ToHelpPageButton>
))

IconTextLink.displayName = 'IconTextLink'
